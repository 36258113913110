import React from 'react';
import { styled } from '@mui/material';

const Body = styled('p')`
  color: #324040;
`;

const About = () => (
  <>
    <Body>
      The Hossainabad Tea Estate is a 999-acre tea estate situated in Kalighat
      road Sreemangal, Moulvibazar. The Estate is home to Hossainabad Tea
      Company Ltd., which was established in 1962 by Kazi Zahirul Quayyum,
      Mokbulur Rahman, and Moksudur Rahman of the Cheora Kazi Bari from Comilla.
    </Body>
    <Body>
      In 1979, Kazi Zahirul Quayyum, a prominent political personality and
      industrialist, became the sole owner of the Hossainabad Tea Company Ltd.
      and eventually he gave some of his shares to his wife Kaniz Fatema and his
      eldest son Kazi Abdul Quayyum. After the death of Kazi Zahirul Quayyum in
      1991, his remaining shares were transferred to Mrs. Kaniz and his five
      sons.
    </Body>
    <Body>
      Upon the death of Mrs. Kaniz in 2005, her five sons – Kazi Abdul Quayyum,
      Kazi Anwarul Quayyum, Kazi Serajul Quayyum, Kazi Shafiq Quayyum, and Kazi
      Rafiq Quayyum – became the owners of the company and currently jointly
      manage its affairs.
    </Body>
    <Body>
      The Company produces CTC black tea which is sold through the Chittagong
      auction house to various corporate buyers and brands.
    </Body>
  </>
);

export default About;
