import React from 'react';
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import {
  Container,
  CssBaseline,
  styled,
  ThemeProvider,
  Typography, useMediaQuery,
} from '@mui/material';
import { createTheme } from '@mui/material/styles';
import './App.css';
import About from './About';
import NavBar from './NavBar';
import Gallery from './Gallery';

const theme = createTheme({
  palette: {
    primary: {
      main: '#4f5b3d',
    },
    secondary: {
      main: '#7a921f',
    },
  },
});

const StyledContainer = styled(Container)`
  overflow-y: scroll;
  height: 100%;
  background-color: white;
  opacity: 0.9;
`;

const Header = styled(Typography)`
  color: #8b855a;
`;

// eslint-disable-next-line react/prop-types
const Root = ({children}) => (
  <StyledContainer maxWidth="md" disableGutters>
    <NavBar />
    <Container>
      {children}
    </Container>
  </StyledContainer>
);

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root><Outlet/></Root>,
    children: [
      {
        index: true,
        element: <About />,
      },
      {
        path: 'gallery',
        element: <Gallery />,
      },
    ],
  },
]);

const App = () => {
  const phone = useMediaQuery('(max-width:900px)');

  return (
    <CssBaseline>
      <ThemeProvider theme={theme}>
        {phone ?
            <Root>
              <About/>
              <Gallery/>
            </Root>
            :
            <RouterProvider router={router} />}
      </ThemeProvider>
    </CssBaseline>
  );
};

export default App;
