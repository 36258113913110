import React from 'react';
import {
    AppBar,
    Container,
    Toolbar,
    Typography, useMediaQuery,
} from '@mui/material';
import Tabs from './Tabs';

const NavBar = () => {
  const phone = useMediaQuery('(max-width:900px)');

  return (
    <>
      <AppBar position="sticky">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography variant={phone ? 'h5' : 'h2'}>Hossainabad Tea Estate</Typography>
          </Toolbar>
        </Container>
      </AppBar>
      <Tabs/>
    </>
  );
};

export default NavBar;
