import React, {useState} from 'react';
import {Tab, Tabs as MUITabs, useMediaQuery} from '@mui/material';
import {Link} from 'react-router-dom';

const Tabs = () => {
    const phone = useMediaQuery('(max-width:900px)');
    const [tab, setTab] = useState('about');

    return (
        phone ? null : <MUITabs
                centered
                value={tab}
                onChange={(event, value) => setTab(value)}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="secondary tabs example"
            >
                <Tab value="about" to="/" label="About" component={Link} />
                <Tab value="gallery" to="/gallery" label="Gallery" component={Link} />
                {/*<Tab value="contact" label="Contact" />*/}
            </MUITabs>
    );
}

export default Tabs;
