import React, {useEffect, useState} from 'react';
import IMG6422 from './images/IMG_6422.jpg';
import IMG6424 from './images/IMG_6424.jpg';
import {Box, styled, useMediaQuery} from '@mui/material';

const items = [
    IMG6422,
    IMG6424,
];

const Gallery = () => {
    const [index, setIndex] = useState(0);
    const phone = useMediaQuery('(max-width:900px)');
    const landscape = useMediaQuery('(orientation:landscape');

    console.log('landscape:', landscape);

    useEffect(() => {
        const timeout = setTimeout(() => setIndex((i) => index === items.length - 1 ? 0 : index + 1), 2000);
        return () => clearTimeout(timeout);
    }, [index]);

    const phoneStyle = {
        marginTop: '5vh',
        marginBottom: '5vh',
        height: 'auto',
        width: '100%',
        textAlign: 'center',
        overflow: 'hidden',
    };

    const desktopStyle = {
        marginTop: '5vh',
        height: '70vh',
        maxHeight: '70vh',
        textAlign: 'center',
        overflow: 'hidden',
    };

    return (
        <Box sx={phone ? phoneStyle : desktopStyle}>
            <div style={{height: '100%'}} >
                <img height="auto" width="100%" style={{objectFit: 'cover'}} src={items[index]} alt="" />
            </div>
        </Box>
    );
};

export default Gallery;
